@font-face {
  font-family: "Big Shoulders Display";
  src: url("./BigShouldersDisplay-Regular.ttf") format("truetype");
  font-weight: 400; /* Graisse normale */
  font-style: normal;
}

@font-face {
  font-family: "Big Shoulders Display Medium";
  src: url("./BigShouldersDisplay-Medium.ttf") format("truetype");
  font-weight: 500;
  /* Graisse medium */
  font-style: normal;
}
@font-face {
  font-family: "Big Shoulders Display SemiBold";
  src: url("./BigShouldersDisplay-SemiBold.ttf") format("truetype");
  font-weight: 600;
  /* Graisse semi-bold */
  font-style: normal;
}

@font-face {
  font-family: "Libre Baskerville";
  src: url("./LibreBaskerville-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: regular;
}

@font-face {
  font-family: "Libre Baskerville Bold";
  src: url("./LibreBaskerville-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: bold;
}
